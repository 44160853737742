<!-- 分时段用电 -->
<template>
  <div id="day-parting">
    <!-- <div class="tree">
      <div class="tree-title">变配电站</div>
      <el-tree
        ref="tree"
        :default-expand-all="false"
        :show-checkbox="false"
        :data="treeData"
        :check-on-click-node="false"
        :props="defaultProps"
        node-key="id"
        @node-click="handleCheck"
      ></el-tree>
    </div> -->
    <div class="main">
      <div class="title">
        <img src="../../assets/images/LTicon.png" alt="" />
        <span>分时段用电</span>
      </div>
      <div class="content">
        <div class="query-form">
          <el-form class="form" ref="form" :inline="true" :model="formData">
            <el-form-item label="日期:" class="query-date">
              <el-date-picker :append-to-body="false" placeholder="请选择日期" value-format="yyyy-MM-dd" type="date"
                v-model="formData.time"></el-date-picker>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" icon="el-icon-search" @click="searchList">搜索</el-button>
              <!-- <el-button type="primary" icon="el-icon-search">图表</el-button> -->
              <!-- <el-button type="primary" icon="el-icon-download">导出</el-button> -->
              <!-- <span class="tip">(*为进线回路)</span> -->
            </el-form-item>
          </el-form>
        </div>
        <el-table class="table" :data="tableData">
          <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
          <el-table-column align="center" min-width="200" prop="loopName" label="回路名称/kw·h">
          </el-table-column>
          <el-table-column align="center" label="尖">
            <el-table-column align="center" prop="one" label="电量" min-width="150">
              <template slot-scope="scope">
                <span style="color: white;">{{ truncateNumber(scope.row.tipPower, 2) }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="two" label="单价" width="120">
              <template slot-scope="scope">
                <!-- <span style="color: rgb(19, 206, 102);">{{ scope.row.tipPowerPrice }}</span> -->
                <span style="color: rgb(19, 206, 102);">0</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="three" label="金额" width="120">
              <template slot-scope="scope">
                <span style="color: rgb(162, 120, 7);">{{ truncateNumber(scope.row.tipPowerPriceTotal, 4) }}</span>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column align="center" label="峰">
            <el-table-column align="center" prop="four" label="电量" min-width="150">
              <template slot-scope="scope">
                <span style="color: white;">{{ truncateNumber(scope.row.peakPower, 2) }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="five" label="单价" width="120">
              <template slot-scope="scope">
                <!-- <span style="color: rgb(19, 206, 102);">{{ scope.row.peakPowerPrice }}</span> -->
                <span style="color: rgb(19, 206, 102);">0</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="six" label="金额" width="120">
              <template slot-scope="scope">
                <span style="color: rgb(162, 120, 7);">{{ truncateNumber(scope.row.peakPowerPriceTotal, 4) }}</span>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column align="center" label="平">
            <el-table-column align="center" prop="seven" label="电量" min-width="150">
              <template slot-scope="scope">
                <span style="color: white;">{{ truncateNumber(scope.row.flatPower, 2) }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="eight" label="单价" width="120">
              <template slot-scope="scope">
                <!-- <span style="color: rgb(19, 206, 102);">{{ scope.row.flatPowerPrice }}</span> -->
                <span style="color: rgb(19, 206, 102);">0</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="nine" label="金额" width="120">
              <template slot-scope="scope">
                <!-- <span style="color: rgb(162, 120, 7);">{{ scope.row.flatPowerPriceTotal.toFixed(2) }}</span> -->
                <span style="color: rgb(162, 120, 7);">0</span>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column align="center" label="谷">
            <el-table-column align="center" prop="ten" label="电量" min-width="150">
              <template slot-scope="scope">
                <span style="color: white;">{{ truncateNumber(scope.row.valleyPower, 2) }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="eleven" label="单价" width="120">
              <template slot-scope="scope">
                <!-- <span style="color: rgb(19, 206, 102);">{{ scope.row.valleyPowerPrice }}</span> -->
                <span style="color: rgb(19, 206, 102);">0</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="twelve" label="金额" width="120">
              <template slot-scope="scope">
                <span style="color: rgb(162, 120, 7);">{{ truncateNumber(scope.row.valleyPowerPriceTotal, 4) }}</span>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column align="center" label="合计">
            <el-table-column align="center" min-width="150" prop="quantity" label="电量/kw·h">
              <template slot-scope="scope">
                <span style="color: white;">{{ truncateNumber(scope.row.totalPower, 2) }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" width="200" prop="total" label="金额(元)">
              <template slot-scope="scope">
                <span style="color: rgb(162, 120, 7);">{{ truncateNumber(scope.row.totalPrice, 4) }}</span>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
import { emissionsCarbonApi } from '@/api/emissionsCarbon.js'
// import { time } from 'echarts';
export default {
  name: "",

  data() {
    return {
      formData: {
        time: "",
      },
      tableData: [],
      select: 0,
    };
  },
  created() {
    this.$store.commit('increment', '用电分析')
    this.$store.commit('selectChild', '分时段用电')
    this.$store.commit("selectChildren", "");
  },

  mounted() {

    this.stationNumber = sessionStorage.getItem('stationNumber')
    this.getPeriodList();
  },


  methods: {
    searchList(){
      this.getPeriodList()
    },
    truncateNumber(value, decimalPlaces) {
      value = parseFloat(value);
      // 检查转换后的值是否为有效数字
      if (isNaN(value)) return '';
      if (value === 0) return '0';
      // 将数值转换为字符串，然后根据指定位数保留小数
      if (decimalPlaces === 4) {
        return 0
        // return value.toFixed(4);
      } else if (decimalPlaces === 2) {
        return value.toFixed(2);
      } else {
        return value.toString().slice(0, value.toString().indexOf('.') + 5);
      }
    },

    getPeriodList() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      let obj = {
        stationNumber: this.stationNumber,
        time: this.formData.time
      }
      if (!this.formData.time) {
        obj.time = `${year}-${month}-${day}`
      }
      emissionsCarbonApi.getPeriodList(obj).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data;
        }
      });
    },
  },
};
</script>
<style src="@/utils/style/public-style.css" scoped></style>
<style scoped>
#day-parting {
  width: 100%;
  min-height: 781px;
  background: url("../../assets/images/rightBG.png") no-repeat;
  background-position: left left;
  background-size: cover;
  font-size: 16px;
  box-sizing: border-box;
  display: flex;
}

/* tree */
.tree :deep() .el-tree {
  width: 240px;
  color: #aed6ff;
  background-color: rgba(255, 255, 255, 0);
  padding: 0 0 10px 21px;
  box-sizing: border-box;
}

.tree-title {
  font-weight: 700;
  color: #d8e3ff;
  font-size: 14px;
  margin: 24px 0 21px 21px;
}

::v-deep .el-tree {
  background: #03183f !important;
  color: #aed6ff;
}

::v-deep .el-tree-node {
  background: #03183f !important;
  color: #aed6ff;
}

::v-deep .is-current {
  background: #03183f !important;
  color: #ffc74a;
}

::v-deep .is-checked {
  background: #03183f !important;
  /* color: #FFC74A; */
}

::v-deep .el-tree-node__content:hover {
  background: #03183f;
  /* color: #FFC74A; */
}

::v-deep.el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {
  background-color: #03183f;
}

::v-deep .el-tree-node {
  background-color: #03183f;
}

::v-deep .el-tree-node:focus>.el-tree-node__content {
  background-color: #03183f;
}

.main {
  min-width: 1280px;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.title {
  font-size: 16px;
  font-family: "Arial", sans-serif;
  color: #e8f4ff;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}

.title img {
  width: 20px;
  height: 20px;
}

.title span {
  font-size: 16px;
  font-family: "Arial", sans-serif;
  color: #e8f4ff;
  margin-left: 10px;
}

/* 查询表单 */
.query-form {
  display: flex;
  align-items: center;
  margin: 18px 0 20px 0;
}

.query-form :deep() .el-form {
  height: 40px;
}

.form :deep() .el-icon-date {
  color: #aed6ff;
}

.form :deep().el-form-item__label {
  color: #aed6ff;
  font-size: 12px;
}

.form :deep() .el-form-item {
  margin: 0;
  margin-right: 8px;
}

/* .form :deep().el-date-editor {
  width: 132px;
} */

.hour :deep() .el-select {
  width: 56px;
  padding: 0;
}

.hour span {
  color: #aed6ff;
  margin: 0 5px 0 8px;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

/* input */
.form :deep() .el-input .el-input__inner {
  background-color: #061b45;
  border: 1px solid #09295b;
  color: #aed6ff;
  height: 40px;
  width: 100%;
}


.tip {
  color: #e8f4ff;
  font-size: 12px;
  margin-left: 10px;
}

/* 表格 */

/* 设置表头的背景颜色 */
.table :deep() thead.is-group th.el-table__cell {
  background: rgb(9, 35, 78);
  color: #e8f4ff;
  font-size: 14px;
}

.table :deep() .el-table__cell {
  padding: 4px 0;
}

.table :deep() tbody tr {
  font-size: 14px;
  color: #aed6ff;
}

/* 设置表格内的背景颜色 */
::v-deep.el-table tbody tr:nth-child(odd) {
  background-color: #071734;
}

::v-deep.el-table tbody tr:nth-child(even) {
  background-color: #071734;
}

::v-deep .el-table {
  border: 2px solid #081f42 !important;
  position: relative;
}

::v-deep.el-table th {
  border: 1px solid rgb(19, 43, 83) !important;
}

::v-deep.el-table td {
  border: 1px solid #0d2247 !important;
}

.table ::v-deep .el-table__body tr:hover>td {
  background-color: #081f42 !important;
}

::v-deep .el-table::before {
  height: 0px;
}

::v-deep .el-table__fixed-right::before {
  height: 0px;
}

::v-deep .el-table__body tr.hover-row>td.el-table__cell {
  background-color: #081f42 !important;
}

/* 滚动条样式 */

/* 更改 el-table 横向滚动条的样式 */
::v-deep .el-table__body-wrapper::-webkit-scrollbar {
  height: 8px;
  /* 设置滚动条高度 */
  background-color: #09234e;
  /* 设置滚动条背景色 */
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: #003d74;
  /* 设置滚动条滑块颜色 */
  border-radius: 5px;
  /* 设置滚动条滑块的圆角 */
}
::v-deep .el-table__body-wrapper {
  height: 72vh;
  overflow: auto;
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar {
  width: 10px;
  /* 设置滚动条高度 */
  background-color: #09234E;
  /* 设置滚动条背景色 */
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: #003d74;
  /* 设置滚动条滑块颜色 */
  border-radius: 5px;
  /* 设置滚动条滑块的圆角 */
}
</style>
