import {getRequest, postRequest, monitorGateway, getDownLoad} from "@/utils/request";
import {get} from "jquery";

export const emissionsCarbonApi = {
    //实时碳排
    realTimeBon: (params) => {
        return getRequest(`${monitorGateway}/stationPowerDataController/getNowCo`, params)
    },
    //碳排统计
    statisticsApi: (params) => {
        return getRequest(`${monitorGateway}/stationPowerDataController/getTotalCo`, params)
    },
    //同比分析
    analysisOneApi: (params) => {
        return getRequest(`${monitorGateway}/stationPowerDataController/getPowerCompare`, params)
    },
    //同比分析系统
    analysisOneApiT: (params) => {
        return getRequest(`${monitorGateway}/statisticalAnalysisController/getPowerCompare`, params)
    },
    //同比分析导出
    getExport: (params) => {
        return getDownLoad(`${monitorGateway}/statisticalAnalysisController/exportPowerCompare`, params);
    },
    //环比分析
    analysisTwoApi: (params) => {
        return getRequest(`${monitorGateway}/stationPowerDataController/getPowerRatio`, params)
    },
    //环比分析系统
    analysisTwoApiT: (params) => {
        return getRequest(`${monitorGateway}/statisticalAnalysisController/getPowerRatio`, params)
    },
    //环比分析导出
    analysisExport: (params) => {
        return getDownLoad(`${monitorGateway}/statisticalAnalysisController/exportPowerRatio`, params);
    },
    //电能质量-电流-电压不平衡-功率因数
    getStationOnceCosq: (params) => {
        return getRequest(`${monitorGateway}/stationPowerDataController/getStationOnceCosq`, params)
    },
    //谐波
    getStationTwiceTnd: (params) => {
        return getRequest(`${monitorGateway}/stationPowerDataController/getStationTwiceTnd`, params)
    },
    //peidiantu
    getStationUrl: (params) => {
        return getRequest(`${monitorGateway}/power/detection/distribution/chart`, params)
    },
    //用电分析
    //用能报表
    getEnergyList: (params) => {
        return getRequest(`${monitorGateway}/stationPowerDataController/getStationPowerReport`, params)
    },
    //用能报表系统
    getEnergyListT: (params) => {
        return getRequest(`${monitorGateway}/statisticalAnalysisController/getStationPowerReport`, params)
    },
    //用能报表导出
    energyExport: (params) => {
        return getDownLoad(`${monitorGateway}/statisticalAnalysisController/exportStationPowerReport`, params);
    },
    //电力运行数据
    selectPowerRunList: (params) => {
        return getRequest(`${monitorGateway}/statisticalAnalysisController/selectPowerRunList`, params)
    },
    //电力运行数据导出
    powerRunExport: (params) => {
        return getDownLoad(`${monitorGateway}/statisticalAnalysisController/exportPowerRunList`, params);
    },

    //电能集抄
    getCollectList: (params) => {
        return getRequest(`${monitorGateway}/stationPowerDataController/getStationPowerDiff`, params)
    },
    //电能集抄系统
    getCollectListT: (params) => {
        return getRequest(`${monitorGateway}/statisticalAnalysisController/getStationPowerDiff`, params)
    },
    //电能集抄导出
    collectExport: (params) => {
        return getDownLoad(`${monitorGateway}/statisticalAnalysisController/exportStationPowerDiff`, params);
    },
    //分时段用电
    getPeriodList: (params) => {
        return getRequest(`${monitorGateway}/stationPowerDataController/getStationTimeIntervalPower`, params)
    },
    //分时段用电系统
    getPeriodListT: (params) => {
        return getRequest(`${monitorGateway}/statisticalAnalysisController/getStationTimeIntervalPower`, params)
    },
    //分时段用电导出
    periodExport: (params) => {
        return getDownLoad(`${monitorGateway}/statisticalAnalysisController/exportStationTimeIntervalPower`, params);
    },

    //实时需量
    getNowChart: (params) => {
        return getRequest(`${monitorGateway}/stationPowerDataController/getStationPowerDemand`, params)
    },
    //电力监测
    //根据场站编码获取回路列表
    getLoopList: (params) => {
        return getRequest(`${monitorGateway}/power/detection/loop`, params)
    },
    //高压侧-点位
    getMainPoint: (params) => {
        return getRequest(`${monitorGateway}/power/detection/high/point`, params)
    },
    //高压侧-列表
    getMainList: (params) => {
        return getRequest(`${monitorGateway}/power/detection/high/list`, params)
    },

    //低压侧 -日原始数据
    getLowList: (params) => {
        return postRequest(`${monitorGateway}/power/detection/low/powerdata`, params)
    },
    //低压侧-逐日极值数据
    getLowListAvg: (params) => {
        return postRequest(`${monitorGateway}/power/detection/low/extremum`, params)
    },
    //低压侧-电力极值报表(日报)
    getExtDayList: (params) => {
        return getRequest(`${monitorGateway}/power/detection/extreme`, params)
    },
    //低压侧-电力极值报表(月报)
    getExtMonthList: (params) => {
        return getRequest(`${monitorGateway}/power/detection/month`, params)
    },
    //低压侧 -电力运行日报
    getRunningList: (params) => {
        return getRequest(`${monitorGateway}/power/detection/daily`, params)
    },
    //变压器-变压器顶部
    getTypeTransForm: (params) => {
        return getRequest(`${monitorGateway}/power/detection/bianyaqi`, params)
    },
    //变压器-变压器顶部
    getTransFormList: (params) => {
        return getRequest(`${monitorGateway}/power/detection/reportform`, params)
    },
    //直流屏
    getScreenList: (params) => {
        return getRequest(`${monitorGateway}/power/detection/directcurrent`, params)
    },
    //尖峰平谷
    getChartCake: (params) => {
        return getRequest(`${monitorGateway}/cockpitel/year/month/day`, params)
    },
    //获取统计分析中的同电量同比分析
    getDianCharts: (params) => {
        return getRequest(`${monitorGateway}/monitors/month/equipment`, params)
    },
    //获取统计分析中的同电量同比分析
    getStationPowerMonth: (params) => {
        return getRequest(`${monitorGateway}/stationPowerDataController/getStationPowerMonth`, params)
    },


}